.App {
  text-align: center;
  border: 5px solid var(--main-text-color);
  border-top-right-radius: 2% 90%;
  border-top-left-radius: 5% 1%;
  border-bottom-left-radius: 10% 5%;
  border-bottom-right-radius: 1% 70%;
  width: 80%;
  height: 10em;
  overflow-y: auto;
}

.connection {
  text-align: center;
  border: 2px solid var(--main-text-color);
  border-top-right-radius: 2% 90%;
  border-top-left-radius: 5% 1%;
  border-bottom-left-radius: 10% 5%;
  border-bottom-right-radius: 1% 70%;
  width: 10%;
  height: 1em;
}

.tabletop {
  text-align: center;
  border: 2px solid var(--main-text-color);
  border-top-right-radius: 2% 90%;
  border-top-left-radius: 5% 1%;
  border-bottom-left-radius: 10% 5%;
  border-bottom-right-radius: 1% 70%;
  width: 95%;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.find-me {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  height: 5em;
  border-radius: 25%;
  border: 1px gray solid;
  margin-right: 5px;
}


.App-link {
  color: #61dafb;
}

a:link {
  color: #61dafb;
}

a:visited {
  color: #96b7c0;
}

a:hover {
  font-style: italic;
}

a:active {
  color: #03bef1;
}

nav > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav > ul > li {
  display: inline-block;
  padding: 2px;
}

.light-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 3em;
  padding-bottom: 1em;
  z-index: 1;
}
.light-container > * {
  z-index: 1;
}

.light{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bead {
  position: relative;
  height: .1em;
  width: .1em;
  border: .05em solid var(--main-text-color);
  border-radius: 99% 97% 95% 94%;
  margin-top: -.1em;
}

.bead:after{
  border: 1px solid var(--main-text-color);
  border-radius: 95% 93% 99% 100%;
  height: .25em;
  width: .25em;

}

.handle {
  height: 1em;
  width: 1em;
  border: .1em solid var(--main-text-color);
  border-radius: 99% 97% 95% 94%;
  box-shadow: 0 0 0 .1em var(--main-text-color);
  margin-top: -.1em;
}

.handle-true{
  background-color: var(--highlight-color2);
  box-shadow: inset  .2em 0 0 .1em var(--main-text-color);

}


.handle-false {
  background-color: var(--main-text-color);
  box-shadow: inset  0 0 .8em 0 var(--highlight-color1);

}

