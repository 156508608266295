:root {
  --main-bg-color: #282c34;
  --main-text-color: #53F4F4;
  --highlight-color1: #EBFDFC;
  --highlight-color2: #E6F4F1;
  --lowlight-color: #4f9d9c;
}

#root > .handle {
  cursor: -webkit-grab;
  white-space: pre;

}
#root > .handle {
  cursor: -webkit-grabbing;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--highlight-color1);
}

a, h1, h2, h3, h4, h5, button {
  font-family: 'Architects Daughter', cursive;
}

